import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
	const userDetails = Boolean(localStorage.getItem('isAuth'))
    console.log(Boolean(localStorage.getItem('isAuth')))
	return !userDetails
		? <Navigate to ="/" />
		: children
  };
 
export default ProtectedRoute;