import ProtectedRoute from '../components/ProtectedRoute';
import HomeLayout from '../layouts/home';

import Login from 'pages/Login';
import Maps from 'pages/Maps';
import TableList from 'pages/Tablelist';
import Signup from 'pages/Signup';

const routes = [
	{
		path: '/',
		element: (
			<Login />
		)	
	},
	{
		path: '/signup',
		element: (
			<Signup />
		)	
	},
	{
		path: '/maps',
		element: (
			<ProtectedRoute>
				<HomeLayout>
					<Maps />
				</HomeLayout>
			</ProtectedRoute>
		)	
	},
	{
		path: '/tablelist',
		element: (
			<ProtectedRoute>
				<HomeLayout>
					<TableList />
				</HomeLayout>
			</ProtectedRoute>
		)	
	},
];

export default routes;