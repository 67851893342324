import React from "react";

import {APIProvider, Map, useApiIsLoaded} from '@vis.gl/react-google-maps';

const Maps = () => {
  const position = {lat: 61.2176, lng: -149.8997};
  console.log(useApiIsLoaded());

  return (
    <div style={{height: '1000px', width: '1000px'}}>
    <APIProvider apiKey={'AIzaSyALhyqwQVphFwfTFXbCL0eWWyz9pnFLBO0'}>
      <Map
      defaultCenter={position}
      //center={position}
      defaultZoom={10}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      mapId={'b34f2b5b9b185b67'}
      />
    </APIProvider>
    </div>
);
}

export default Maps;
