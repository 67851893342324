import React from 'react';
import { Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbars from "../pages/Navbars"


const HomeLayout = ({children}) => {
  return (
    <Container>
      <Navbars />
      {children}
    </Container>
  );
}

export default HomeLayout;