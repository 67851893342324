import routes from './routes/routes.jsx';
import {BrowserRouter, useRoutes} from 'react-router-dom';

function App () {
  localStorage.removeItem('isAuth');
  localStorage.setItem('isAuth', false);
  const element = useRoutes(routes);
  return element;
}

export default () => <BrowserRouter><App /></BrowserRouter>
