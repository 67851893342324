import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCOdqJARB8Jv4tTJNIDTU9_n--HyUeT7oY",
    authDomain: "diftah24march.firebaseapp.com",
    databaseURL: "https://diftah24march-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "diftah24march",
    storageBucket: "diftah24march.appspot.com",
    messagingSenderId: "474335665212",
    appId: "1:474335665212:web:0a86abc5a2223990ceb739",
    measurementId: "G-CGVZHEGZMK"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app}